import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadViewer } from 'common/actions/viewer';
import LoginForm from 'common/auth/LoginForm';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import LazyLoadedImage from 'common/LazyLoadedImage';
import devURL from 'common/util/devURL';
import queryString from 'common/util/queryString';
import withContexts from 'common/util/withContexts';

import 'css/components/auth/_Install.scss';

class Install extends Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
    viewer: PropTypes.shape({
      _id: PropTypes.string,
      admin: PropTypes.bool,
    }),
  };

  componentDidMount() {
    const { viewer } = this.props;
    const { companyID } = queryString.parse(window.location.search);
    if (!viewer || viewer.loggedOut) {
      return;
    } else if (!viewer.companies) {
      return;
    } else if (viewer.companies.length !== 1) {
      const company = viewer.companies.find((company) => company._id === companyID);
      if (!company) {
        return;
      }

      this.redirect(viewer, company);
      return;
    }

    this.redirect(viewer);
  }

  onSuccess = (user) => {
    if (!user || !user.companies || user.companies.length !== 1) {
      this.props.reloadViewer();
      return;
    }

    this.redirect(user);
  };

  redirect(user) {
    const { path } = this.props;
    const { companies } = user;
    const company = companies.find((c) => !c.integrationAuthRedirectionURL) ?? companies[0];

    if (company.integrationAuthRedirectionURL) {
      window.location.replace(devURL(company.integrationAuthRedirectionURL));
      return;
    }

    const companyURL = devURL(`https://${company.subdomain}.canny.io${path}`);
    window.location.replace(companyURL);
  }

  render() {
    const { viewer } = this.props;
    if (!viewer || viewer.loggedOut) {
      return (
        <div className="install">
          <div className="text">Log into your Canny account to set up this integration.</div>
          <LoginForm onSuccess={this.onSuccess} />
        </div>
      );
    } else if (viewer.companies.length === 0) {
      return (
        <div className="install">
          <div className="text">Your Canny account isn't associated with a company account.</div>
          <LoginForm onSuccess={this.onSuccess} />
        </div>
      );
    } else if (viewer.companies.length === 1) {
      // redirecting
      return <div />;
    } else {
      const { path } = this.props;
      const items = viewer.companies.map((company, i) => {
        const { subdomain } = company;
        const companyURL = devURL(`https://${subdomain}.canny.io${path}`);
        return (
          <a href={companyURL} key={company._id} className="companyLink">
            {company.logoURL ? (
              <LazyLoadedImage alt={`${company.name} logo`} src={company.logoURL} />
            ) : null}
            <div className="companyName">{company.name}</div>
          </a>
        );
      });
      return (
        <div className="install">
          <div className="text">Choose a Canny account to integrate with:</div>
          <div className="companyList">{items}</div>
        </div>
      );
    }
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadViewer: () => {
      return Promise.all([dispatch(reloadViewer())]);
    },
  })),
  withContexts(
    {
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(Install);
