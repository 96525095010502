import { CookieNames } from 'common/cookies/cookies';

type CookieValue = string | null;
export default function getCookies(cookieNames: string[]): CookieValue[] {
  if (typeof document === 'undefined') {
    return [];
  }

  // in dev environment with client-side rendering, we still use js cookies
  const areHttpCookiesEnforced =
    !__DEV__ || __TEST__ || __SSR_LOCAL_SERVER__ || __SSR_TEST_RUNNER__;
  if (areHttpCookiesEnforced) {
    const httpOnlyCookie = Object.values(CookieNames).find((httpOnlyCookieName) => {
      return cookieNames.includes(httpOnlyCookieName);
    });

    if (httpOnlyCookie) {
      // http only cookies aren't available in javascript, you cannot use `getCookies`
      throw new Error(`getCookies called for HttpOnly cookie: ${httpOnlyCookie}`);
    }
  }

  const cookies: Record<string, CookieValue> = {};
  document.cookie.split(';').map(function (cookie) {
    const parts = cookie.split('=');
    const name = decodeURIComponent((parts.shift() || '').trim());
    const value = parts.join('=').trim();
    cookies[name] = value ? decodeURIComponent(value.trim()) : null;
  });

  const cookieValues: CookieValue[] = [];
  cookieNames.forEach((cookieName) => {
    cookieValues.push(cookies[cookieName] || null);
  });

  return cookieValues;
}
