import React, { Component } from 'react';

import PropTypes from 'prop-types';

import AJAX from 'common/AJAX';
import * as AuthRequests from 'common/auth/AuthRequests';
import AuthButton from 'common/AuthButton';
import EmailButton from 'common/EmailButton';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import Strings from 'common/Strings';
import Tappable from 'common/Tappable';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import validateInput from 'common/validateInput';

import 'css/components/auth/_LoginForm.scss';

export default class LoginForm extends Component {
  static propTypes = {
    acceptedEmail: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
    queryAllowlist: PropTypes.object,
  };

  static defaultProps = {
    queryAllowlist: {},
  };

  state = {
    emailMode: false,
    error: null,
    forgotMode: false,
    reset: false,
    submitting: false,
  };

  constructor(props) {
    super(props);

    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();
  }

  onEmailFormSubmit = (e) => {
    e.preventDefault();

    if (this.state.submitting) {
      return;
    }

    const { acceptedEmail } = this.props;
    const email = this.emailRef.current.getValue().trim();
    const password = this.passwordRef.current.getValue();

    let error = null;
    if (!validateInput.email(email)) {
      error = Strings.invalidEmail;
    } else if (!validateInput.password(password)) {
      error = Strings.invalidPassword;
    } else if (acceptedEmail && email !== acceptedEmail) {
      error = `You are logging in with a different email than the invite. Please log in with the correct email (${acceptedEmail}).`;
    }

    if (error) {
      this.setState({ error });
      return;
    }

    const requestData = {
      email: email,
      password: password,
    };

    this.onLogin(requestData);
  };

  onOAuthFailure = (error) => {
    this.setState({
      error,
    });
  };

  onForgotSubmit = (e) => {
    e.preventDefault();

    if (this.state.submitting) {
      return;
    }

    const email = this.emailRef.current.getValue().trim();
    if (!email || !validateInput.email(email)) {
      this.setState({
        error: 'Please enter a valid email',
      });
      return;
    }

    AJAX.post(
      '/api/viewer/forgotPassword',
      {
        email: email,
      },
      this.onForgotPasswordResponse
    );

    this.setState({
      error: null,
      submitting: true,
    });
  };

  onForgotPasswordResponse = (response) => {
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'no such email': 'There is no account associated with that email, please try again.',
        'slow down':
          'You are trying to reset your password too many times. Please wait a few minutes before trying again.',
      },
    });

    if (!error) {
      this.setState({
        submitting: false,
        reset: true,
      });
      return;
    }

    this.setState({
      submitting: false,
      error: error.message,
    });
  };

  onLogin = async (requestData) => {
    const { acceptedEmail } = this.props;
    this.setState({
      error: null,
      submitting: true,
    });

    if (acceptedEmail && requestData.email !== acceptedEmail) {
      this.setState({
        error: `Please log in with the email you were invited with (${acceptedEmail}).`,
        submitting: false,
      });
      return;
    }

    const { error, parsedResponse, redirecting } = await AuthRequests.login(requestData);

    if (redirecting) {
      return;
    }

    if (!error) {
      this.setState(
        {
          error: null,
          submitting: false,
        },
        () => this.props.onSuccess(parsedResponse.user)
      );
      return;
    }

    this.setState({
      error: error.message,
      submitting: false,
    });
  };

  onSetMode = (mode) => () => {
    this.setState({
      emailMode: mode === 'email',
      error: null,
      forgotMode: mode === 'forgot',
    });
  };

  renderAuthButtons() {
    if (this.state.emailMode || this.state.forgotMode) {
      return null;
    }

    return (
      <>
        {this.renderErrorMessage()}
        <div className="authButtons">
          <AuthButton
            authType="google"
            onFailure={this.onOAuthFailure}
            onSuccess={this.onLogin}
            queryAllowlist={this.props.queryAllowlist}
            value="Log in with Google"
          />
          <AuthButton
            authType="facebook"
            onFailure={this.onOAuthFailure}
            onSuccess={this.onLogin}
            queryAllowlist={this.props.queryAllowlist}
            value="Log in with Facebook"
          />
          <AuthButton
            authType="github"
            onFailure={this.onOAuthFailure}
            onSuccess={this.onLogin}
            queryAllowlist={this.props.queryAllowlist}
            value="Log in with GitHub"
          />
          <EmailButton onTap={this.onSetMode('email')} value="Log in with Email" />
        </div>
      </>
    );
  }

  renderEmailForm() {
    const { acceptedEmail } = this.props;
    if (!this.state.emailMode) {
      return null;
    }

    return (
      <form className="emailForm" onSubmit={this.onEmailFormSubmit}>
        <TextInput
          autoFocus={true}
          placeholder="Email"
          ref={this.emailRef}
          {...(acceptedEmail ? { value: acceptedEmail, disabled: true } : null)}
        />
        <TextInput placeholder="Password" ref={this.passwordRef} type="password" />
        {this.renderErrorMessage()}
        <div className="buttonContainer">
          <Tappable onTap={this.onSetMode('forgot')}>
            <span className="greyLink">Forgot password?</span>
          </Tappable>
          <Button
            buttonType="cannyButton"
            className="submitButton"
            formButton={true}
            loading={this.state.submitting}
            value="Log In"
          />
        </div>
      </form>
    );
  }

  renderForgotForm() {
    if (!this.state.forgotMode) {
      return null;
    }

    if (this.state.reset) {
      return <div className="reset">Check your email to finish resetting your&nbsp;password.</div>;
    }

    return (
      <form className="forgotForm" onSubmit={this.onForgotSubmit}>
        <TextInput autoFocus={true} placeholder={'Email'} ref={this.emailRef} />
        {this.renderErrorMessage()}
        <div className="buttonContainer">
          <Tappable onTap={this.onSetMode('email')}>
            <span className="greyLink">Cancel</span>
          </Tappable>
          <Button
            buttonType="cannyButton"
            className="submitButton"
            formButton={true}
            loading={this.state.submitting}
            value="Reset Password"
          />
        </div>
      </form>
    );
  }

  renderErrorMessage() {
    return this.state.error ? <div className="error">{this.state.error}</div> : null;
  }

  render() {
    return (
      <div className="loginForm">
        {this.renderAuthButtons()}
        {this.renderEmailForm()}
        {this.renderForgotForm()}
      </div>
    );
  }
}
