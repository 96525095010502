import React from 'react';

import LazyLoadedImage from 'common/LazyLoadedImage';

import Tappable from './Tappable';

import EmailIcon from 'img/email-icon.svg';

import 'css/components/_AuthButton.scss';

type Props = {
  onTap: () => void;
  value: string;
};

const EmailButton = ({ onTap, value }: Props) => {
  return (
    <Tappable onTap={onTap}>
      <div className="authButton">
        <div className="imageContainer">
          <LazyLoadedImage src={EmailIcon} />
        </div>
        <div className="cta">{value}</div>
      </div>
    </Tappable>
  );
};
export default EmailButton;
